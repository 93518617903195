<template>
  <div class="stepContent commonClass">
    <h3 class="titHead">药前记录</h3>
    <div class="detail">
      <div class="flex  backReturn">
        <h4 class="title">药前体重、瘤径记录</h4>
        <el-button size="small" plain class="return" @click="$emit('changePage', 1,null,0)">返回列表页</el-button>
      </div>
      <el-form ref="addForm" size="small" :model="preMedi" class="addForm" label-suffix=":"
               label-width="90px">
        <el-form-item label="操作室地点">
          <el-input v-model="preMedi.location" placeholder="请输入操作室地点" clearable class="smallWidth"></el-input>
        </el-form-item>
        <el-form-item label="使用仪器">
          <div v-for="(obj,ind) in preMedi.instrument" :key="ind" class="modeFor">
            <el-input v-model="obj.instrumentName" :placeholder='"请输入仪器"+(ind+1)+"名称"' clearable
                      class="smallWidth"></el-input>
            <el-input v-model="obj.instruModel" :placeholder='"请输入仪器"+(ind+1)+"型号"' clearable
                      class="smallWidth"></el-input>
            <el-button size="small" plain class="delIns" icon="el-icon-close" @click="delIns(ind)"></el-button>
          </div>
          <el-button icon="el-icon-plus" class="addInstru" @click="addInstru">添加仪器</el-button>
        </el-form-item>
        <el-table
            :data="preMedi.preMediDetailList" border
            :row-class-name="tableRowClassName"
            class="tableMember"
            :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}">
          <el-table-column label="笼舍编号" show-overflow-tooltip min-width="130">
            <template slot-scope="scope">
              <el-input v-model="animal.cageNo" size="small" v-if="scope.$index==0"
                        placeholder="请输入笼舍编号"></el-input>
              <template v-else>
                <template v-if="scope.row.show">
                  <el-input size="small" placeholder="请输入笼舍编号" v-model="scope.row.cageNo"></el-input>
                </template>
                <span v-else>{{ scope.row.cageNo }}</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column label="笼架编号" show-overflow-tooltip min-width="130">
            <template slot-scope="scope">
              <el-input v-model="animal.frameNo" size="small" v-if="scope.$index==0"
                        placeholder="请输入笼架编号"></el-input>
              <template v-else>
                <template v-if="scope.row.show">
                  <el-input size="small" placeholder="请输入笼架编号" v-model="scope.row.frameNo"></el-input>
                </template>
                <span v-else>{{ scope.row.frameNo }}</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column label="耳号" show-overflow-tooltip min-width="130">
            <template slot-scope="scope">
              <el-input v-model="animal.overbit" size="small" v-if="scope.$index==0"
                        placeholder="请输入耳号"></el-input>
              <template v-else>
                <template v-if="scope.row.show">
                  <el-input size="small" placeholder="请输入耳号" v-model="scope.row.overbit"></el-input>
                </template>
                <span v-else>{{ scope.row.overbit }}</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column label="性别" min-width="130">
            <template slot-scope="scope">
              <el-select v-model="animal.gender" v-if="scope.$index==0" size="small" placeholder="请选择性别">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
              <template v-else>
                <template v-if="scope.row.show">
                  <el-select v-model="scope.row.gender" size="small" placeholder="请选择性别">
                    <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </template>
                <span v-else>{{ scope.row.gender == 0 ? '♀' : '♂' }}</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column label="体重(g)" min-width="130">
            <template slot-scope="scope">
              <el-input v-model="animal.weight" size="small" v-if="scope.$index==0"
                        placeholder="请输入体重"></el-input>
              <template v-else>
                <template v-if="scope.row.show">
                  <el-input size="small" placeholder="请输入体重" v-model="scope.row.weight"></el-input>
                </template>
                <span v-else>{{ scope.row.weight }}</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column label="瘤径a(mm)" show-overflow-tooltip width="140">
            <template slot-scope="scope">
              <el-input v-model="animal.tumorSizeA" size="small" v-if="scope.$index==0"
                        placeholder="请输入瘤径a(mm)"></el-input>
              <template v-else>
                <template v-if="scope.row.show">
                  <el-input size="small" placeholder="请输入瘤径a(mm)" v-model="scope.row.tumorSizeA"></el-input>
                </template>
                <span v-else>{{ scope.row.tumorSizeA }}</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column label="瘤径b(mm)" show-overflow-tooltip width="140">
            <template slot-scope="scope">
              <el-input v-model="animal.tumorSizeB" size="small" v-if="scope.$index==0"
                        placeholder="请输入瘤径b(mm)"></el-input>
              <template v-else>
                <template v-if="scope.row.show">
                  <el-input size="small" placeholder="请输入瘤径b(mm)" v-model="scope.row.tumorSizeB"></el-input>
                </template>
                <span v-else>{{ scope.row.tumorSizeB }}</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column label="备注" show-overflow-tooltip min-width="140">
            <template slot-scope="scope">
              <el-input v-model="animal.remark" size="small" v-if="scope.$index==0"
                        placeholder="请输入备注"></el-input>
              <template v-else>
                <template v-if="scope.row.show">
                  <el-input size="small" placeholder="请输入备注" v-model="scope.row.remark"></el-input>
                </template>
                <span v-else>{{ scope.row.remark }}</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="140" fixed="right">
            <template slot-scope="scope">
              <div v-if="scope.$index==0" class="addColor">
                <el-button type="text" @click="add">添加</el-button>
                <el-button type="text" @click="reset">重置</el-button>
              </div>
              <div v-else class="editColor">
                <template v-if="state==0">
                  <el-button type="text" v-if="!scope.row.show" @click="edit(scope.row,scope.$index)">修改</el-button>
                  <el-button type="text" v-else @click="save(scope.row)">保存</el-button>
                </template>
                <el-button type="text" v-else @click="edit(scope.row,scope.$index)">{{ scope.row.show ? '保存' : "修改" }}
                </el-button>
                <el-button type="text" @click="copy(scope.row,scope.$index)">复制</el-button>
                <el-button type="text" @click="deleteA(scope.row.id,scope.$index)">删除</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <h3 class="tit">指定复核人</h3>
        <el-form-item label="复核人员">
          <el-radio-group v-model="preMedi.reviewName" class="radioGroup">
            <el-radio v-for="item in userList" :label="item.name" :key="item.userId"
                      @click.native="getAppId(item.userId,item.name)"
            > {{ item.name }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <div class="fromSave">
          <el-button type="primary" size="small" @click="submitRecord(1)">记录完成</el-button>
          <el-button plain type="primary" size="small" @click="submitRecord(0)">保存</el-button>
          <template v-if="infoId">
            <el-button plain type="primary" size="small" @click="$emit('changePage', 3, infoId,0)">导出预览</el-button>
          </template>
        </div>
      </el-form>
    </div>
  </div>

</template>

<script>
let top = {
  overbit: "",
  cageNo: "",
  frameNo: "",
  gender: "",
  tumorSizeA: "",
  tumorSizeB: "",
  weight: "",
  remark: "",
  show: false
}
export default {
  name: "medicineBefore",
  props: ['id', 'infoId'],
  data() {
    return {
      preMedi: {
        location: '',
        instrument: [],
        reviewer: null,
        reviewName: '',
        preMediDetailList: []
      },
      userList: [],
      state: null,
      animal: {
        overbit: "",
        cageNo: "",
        frameNo: "",
        gender: "",
        tumorSizeA: "",
        tumorSizeB: "",
        weight: "",
        remark: "",
      },
      options: [{
        value: 0,
        label: '雌性'
      }, {
        label: '雄性',
        value: 1
      }],
    }
  },
  created() {
    this.preMedi.preMediDetailList.unshift(top)
  },
  mounted() {
    this.$get('/subject/member/list/' + this.id).then(res => {
      if (res.status == 200) {
        this.userList = res.data
      }
    })
    // 获取详情
    if (this.infoId) {
      this.getDetail()
    }
  },
  methods: {
    // 指定复核人员
    getAppId(id, name) {
      this.preMedi.reviewer = id
      this.preMedi.reviewName = name
    },
    addInstru() {
      this.preMedi.instrument.push({
        instrumentName: "",
        instruModel: ""
      })
    },
    delIns(index) {
      this.preMedi.instrument.splice(index, 1)
    },
    // 设置第一行样式
    tableRowClassName({row, rowIndex}) {
      if (rowIndex === 0) {
        return 'formAdd';
      }
      return '';
    },
    // 添加
    add() {
      if ($.trim(this.animal.overbit).length === 0) {
        this.$message.warning("耳号不能为空");
        return;
      }
      if ($.trim(this.animal.gender).length === 0) {
        this.$message.warning("性别不能为空");
        return;
      }
      if ($.trim(this.animal.weight).length === 0) {
        this.$message.warning("体重不能为空");
        return;
      }
      // 保存状态下新增 或者退回状态下
      if (this.state == 0 || this.state == 3) {
        this.animal.preMediId = this.infoId
        this.addTable(this.animal)
      } else {
        this.preMedi.preMediDetailList.push(this.animal)
      }
      this.animal = {
        overbit: "",
        cageNo: "",
        frameNo: "",
        gender: "",
        tumorSizeA: "",
        tumorSizeB: "",
        weight: "",
        remark: "",
      }
    },
    // 保存状态下新增
    addTable(animal) {
      this.$post('/pre/medi/detail', animal).then(res => {
        if (res.status == 201) {
          this.$message.success("新增成功");
          this.$nextTick(() => {
            this.getMediTab()
          })
        }
      })
    },
    // 重置
    reset() {
      this.animal = {
        overbit: "",
        cageNo: "",
        frameNo: "",
        gender: "",
        tumorSizeA: "",
        tumorSizeB: "",
        weight: "",
        remark: "",
      }
    },
    // 编辑
    edit(row, index) {
      row.show = !row.show
      this.$set(this.preMedi.preMediDetailList, index, row)
    },
    // 保存状态下 -- 保存
    save(row) {
      this.$put('/pre/medi/detail', row).then(res => {
        if (res.status == 200) {
          this.$message.success('修改成功')
          this.$nextTick(() => {
            this.getMediTab()
          })
        }
      })
    },
    // 复制
    copy(val, index) {
      if (this.state == 0 || this.state == 3) {
        this.$delete(val, 'id')
        this.addTable(val)
      } else {
        this.preMedi.preMediDetailList.splice(index, 0, JSON.parse(JSON.stringify(val)))
      }
    },
    // 删除
    deleteA(id, index) {
      if (this.state == 0 || this.state == 3) {
        this.$del('/pre/medi/detail/' + id).then(res => {
          if (res.status == 200) {
            this.$message.success('删除成功')
            this.$nextTick(() => {
              this.getMediTab()
            })
          }
        })
      } else {
        this.preMedi.preMediDetailList.splice(index, 1)
      }
    },
    // 增删改成功之后调用--记录详情表
    getMediTab() {
      this.$get('/pre/medi/detail/' + this.infoId).then(res => {
        if (res.status == 200) {
          this.preMedi.preMediDetailList = res.data
          this.preMedi.preMediDetailList.unshift(top)
        }
      })
    },
    // 详情
    getDetail() {
      this.$get('/pre/medi/' + this.infoId).then(res => {
        if (res.status == 200) {
          this.state = res.data.state
          let newObj = {}
          newObj = {
            id: res.data.id,
            location: res.data.location,
            instrument: JSON.parse(res.data.instrument),
            reviewer: res.data.reviewer,
            reviewName: res.data.reviewName,
          }
          newObj.preMediDetailList = this.preMedi.preMediDetailList.concat(res.data.preMediDetailList)
          this.preMedi = newObj
        }
      })
    },
    // 提交
    submitRecord(state) {
      this.preMedi.preMediDetailList.splice(0, 1)
      if ($.trim(this.preMedi.reviewer).length === 0) {
        this.$message.warning("请选择复核人")
        return false;
      }
      if (this.state == 0 || this.state == 3) {
        let params = {
          id: this.infoId,
          state: state,
          subId: Number(this.id),
          location: this.preMedi.location,
          instrument: JSON.stringify(this.preMedi.instrument),
          reviewer: this.preMedi.reviewer,
          reviewName: this.preMedi.reviewName,
        }
        this.$put('/pre/medi', params).then(res => {
          if (res.status == 200) {
            this.$message.success("修改成功信息")
            this.$emit('changePage', 1, null, 0)
          }
        })
      } else {
        if (this.preMedi.preMediDetailList.length == 0) {
          this.$message.info("请先输入动物信息")
        }
        this.preMedi.state = state
        this.preMedi.subId = Number(this.id)
        this.preMedi.instrument = JSON.stringify(this.preMedi.instrument)
        this.$postJson('/pre/medi', this.preMedi).then(res => {
          this.$emit('changePage', 1, null, 0)
        })
      }

    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/exper/experiDetail";


</style>
